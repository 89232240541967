<template>
  <el-upload
    class="upload-demo"
    ref="clearPicFiles"
    action="http://120.53.222.45:3005/api/webapi_baozhuang/uploadFile"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :on-success="handleAvatarSuccess"
    :on-error="handleError"
    :before-upload="beforeAvatarUpload"
    :data="datas"
    :headers="token"
    name="file"
    list-type="picture"
  >
    <el-button size="small" type="primary">{{ uploadText }}</el-button>
    <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1024kb</div> -->
  </el-upload>
</template>

<script>
// import { getToken } from "@/utils/auth"
export default {
  props: {
    uploadText: {
      type: String,
      default: "点击上传图片"
    },
    size: {
      type: Number,
      default: 1024
    }
  },
  computed: {
    datas() {
      return this.$store.state.fileNameObj;
    }
  },
  data() {
    return {
      token: {}
      // fileList: [{name: 'food. jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    };
  },
  mounted() {
    this.token = { ClientKey: "0535deer" };
  },
  methods: {
    clearPictureFiles() {
      this.$refs.clearPicFiles.clearFiles();
    },
    // 删除图片
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //
    handlePreview(file) {
      console.log(file);
    },
    splitD(url) {
      return url.replace(/\,/g, "");
    },
    // 上传成功
    handleAvatarSuccess(res, file) {
      if (!this.$store.state.fileNameObj.fileName) {
        return;
      }
      if (!res.code) {
        this.$message({
          type: "danger",
          message: `上传失败！`
        });
        return;
      }
      this.$emit("imgUploadSuccess", res.uuid);
      this.$message({
        type: "success",
        message: `上传成功`
      });
    },
    // 上传失败
    handleError() {
      this.$message({
        type: "danger",
        message: `上传失败，请检查网络或稍后上传！`
      });
    },
    // 上传前判断
    beforeAvatarUpload(res, file) {
      const _self = this;
      console.log("上传前：", this.$store.state.fileNameObj);
      if (!this.$store.state.fileNameObj.fileName) {
        this.$message({
          type: "danger",
          message: `请先填写用户名和项目名`
        });
        return false;
      }
      const size = parseInt(res.size / 1024);
      if (this.size && size > this.size) {
        this.$message({
          type: "danger",
          message: `图片尺寸过大, 要求${this.size}kb, 当前尺寸${size}kb`
        });
        return;
      }
      // let imgType = {
      //   'image/png': 'png',
      //   'image/jpeg': 'jpg',
      //   'image/gif': 'gif'
      // }
      // imgType = imgType[file.type] || '' // 图片类型
      // const name = _self.getRandonName() // 图片名字
      // 与编辑器相关
      // const _URL = window.URL || window.webkitURL
      // const fileName = file.uid
      // this.listObj[fileName] = {}
      // promise
      // return new Promise((resolve, reject) => {
      //   // 与编辑器
      //   const img = new Image()
      //   img.src = _URL.createObjectURL(file)
      //   img.onload = function() {
      //     _self.listObj[fileName] = { hasSuccess: true, uid: file.uid, width: this.width, height: this.height }
      //   }
      //   // 发送阿里云
      //   getToken({ updir: _self.upDir }).then(r => {
      //     // console.log('1', name)
      //     // console.log('2', _self.upDir)
      //     _self._data.upData = r.data
      //     _self._data.upData['key'] = _self.upDir + name + '.' + imgType
      //     console.log('321', _self._data.upData['key'])
      //     _self._data.fullImgUrl = _self._data.upData['host'] + '/' + _self._data.upData['key']
      //     console.log('rrrrrrrrr', _self._data.upData['host'])
      //     resolve(true)
      //   }).catch(err => {
      //     console.log(err)
      //     reject(false)
      //   })
      // })
    }
    // progress(event, file, fileList) {
    //   this.upProgress = parseInt(event.percent)
    // }
  }
};
</script>

<style lang="scss" scoped></style>
