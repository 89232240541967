<template>
  <div class="baozhuang-box" v-loading="loading">
    <el-form
      ref="form"
      :model="row"
      label-position="right"
      :rules="rules"
      label-width="130px"
    >
      <el-form-item label="用户名称" prop="userName">
        <el-input v-model="row.userName" @change="handleChange"></el-input>
      </el-form-item>
      <el-form-item label="项目名称" prop="projectName">
        <el-input v-model="row.projectName" @change="handleChange"></el-input>
      </el-form-item>
      <el-form-item label="项目地址" prop="projectAddr">
        <el-input v-model="row.projectAddr"></el-input>
      </el-form-item>
      <el-form-item
        label="电子邮箱"
        prop="email"
        :rules="[
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change']
          }
        ]"
      >
        <el-input v-model="row.email"></el-input>
      </el-form-item>

      <el-form-item label="报装类型" prop="bzlxId">
        <el-select v-model="row.bzlxId" placeholder="请选择报装类型">
          <el-option
            v-for="item in baozhuangOptions"
            :key="item.id"
            :label="item.bzlx"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="经办人" prop="jbr">
        <el-input v-model="row.jbr"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phoneNum">
        <el-input v-model="row.phoneNum"></el-input>
      </el-form-item>
      <el-form-item label="供水报装申请表" prop="">
        <up-load @imgUploadSuccess="imgUploadSuccess" ref="getUploadClearFn" />
      </el-form-item>
      <el-form-item label="相关文件下载" prop="">
        <a
          href="http://mpgszgs.cn:3005/File/一户一表改造工程申请表.xls"
          style="color: rgb(0, 121, 219);font-weight: bold;display: flex; flex-direction: column;"
          download=""
        >
          《一户一表改造工程申请表.xls》
        </a>
        <a
          href="http://mpgszgs.cn:3005/File/%E3%80%8A%E7%89%9F%E5%B9%B3%E4%BE%9B%E6%B0%B4%E6%8A%A5%E8%A3%85%E7%94%B3%E8%AF%B7%E8%A1%A8%E3%80%8B.xls"
          style="color: rgb(0, 121, 219);font-weight: bold;display: flex; flex-direction: column;"
          download=""
        >
          《牟平供水报装申请表.xls》<br />
          <span style="color: #ff3232;font-weight: 100;"
            >(1) 下载后填写完整,打印后盖章拍照上传</span
          >
          <span style="color: #ff3232;font-weight: 100;padding:0;"
            >(2) 如果手机不能下载，请在电脑浏览器打开</span
          >
          <span style="color: #ff3232;font-weight: 100;"
            >(3) 咨询电话 ：4339329 / 4339335</span
          >
        </a>
      </el-form-item>
    </el-form>
    <el-button
      style="width: 100%;"
      type="primary"
      @click="confirmCreate('form')"
      >确认提交</el-button
    >
    <el-dialog
      title="供水报装业务用户须知"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <span
        >尊敬的用户：<br />
        欢迎您来到烟台市牟平区润新供水有限责任公司办理供水报装业务！请您仔细阅读以下内容，我们将为您提供便捷、高效的接水服务。<br />
        一、业务类型<br />
        1. 新建住宅居民小区接水业务：
        新建居民住宅小区内供水设施配套建设及接水业务；<br />
        2. 临时用水业务：用于新建项目工地临时施工用水的接水业务；<br />
        3. 消防用水业务：单位用水为满足消防安全规范申请的业务； <br />
        4.
        改表业务：改表业务是用户在不改变原用水范围的情况下，因用水量发生变化，现有水表口径不能满足用水需求或影响水表计量的，申请变更水表口径的业务。<br />
        5.
        分表业务：用户因原贸易结算水表内存在两种以上不同用水性质或者存在不同用水主体，申请将单只水表分为多只水表计量的业务；<br />
        6.
        移表井业务：指有合理原因的表井产权人及产权单位需将现有水表井移至其他位置且还为该建筑供水而申请的业务；<br />
        7.
        移管线业务：指在工地施工及其他合理原因的情况下由用户出资申请需将市政管网迁移位置的业务；<br />
        8.
        综合楼、厂房、平房、网点业务：单位及个人用户新装正式用水，申请接入市政供水的业务。<br />
        9.一户一表改造：居民住宅一户一表改造业务是使用烟台市牟平区润新供水有限责任公司市政管网供水，并具备一户一表改造条件的居民住宅申请的改造业务。<br />
        二、办事程序<br />
        用户申请→制定方案（施工图设计、签订协议、工程施工）→开阀供水<br />
        三、用户需提报的资料<br />
        项目相关图纸及资料<br />
        四、用户须知<br />
        1.
        报装用户通过供水服务报装业务模块下载《供水报装申请表》。将填写完整的《供水报装申请表》打印一份，拍照盖印后上传；<br />
        2.
        您的资料经审核，齐全、准确后，牟平区润新供水有限责任公司对该项业务予以正式受理；<br />
        五、联系方式<br />
        咨询电话：4339329 / 4339335 <br />
        工作时间：周一至周五（国家法定节假日除外） <br />
        电子邮箱：khfwx@yt.shandong.cn<br />
        地 址：牟平区润新供水有限责任公司客户服务中心营业厅（4339329）
        牟平区政务服务中心二楼公共事业服务区37号窗口（4339335）</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getInfoGet, getInfoPost } from "@/api/public";
import { validUsername } from "@/utils/validate";
import UpLoad from "@/components/upload/upload";
export default {
  components: {
    UpLoad
  },
  data() {
    const is_Mobile = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        callback();
      }
    };
    return {
      row: {
        userName: "",
        projectName: "",
        projectAddr: "",
        email: "",
        bzlxId: "",
        phoneNum: "",
        jbr: "",
        uuid: ""
      },
      downPath: "../../public/sqb.xls",
      upLoadText: "请上传申请表图片",
      fileName: "",
      baozhuangOptions: [],
      loading: false,
      dialogVisible: false,
      ws: null,
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名称",
            trigger: "blur"
          }
        ],
        projectName: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur"
          }
        ],
        projectAddr: [
          {
            required: true,
            message: "请输入项目地址",
            trigger: "blur"
          }
        ],
        // email: [{
        //     required: true,
        //     message: '邮箱不正确',
        //     trigger: 'blur'
        // }],
        bzlxId: [
          {
            required: true,
            message: "请输报装类型",
            trigger: "blur"
          }
        ],
        phoneNum: [
          {
            required: true,
            message: "请输入正确的手机号",
            trigger: "blur",
            validator: is_Mobile
          }
        ],
        jbr: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.getTypeList();
    this.dialogVisible = true;
  },
  mounted() {
    this.ws = new WebSocket("ws://221.214.180.145:50000");
    // 当一个websockt连接成功是触发
    this.ws.onopen = function(e) {
      console.log("websockt连接成功", e);
    };
  },
  methods: {
    imgUploadSuccess(uuid) {
      console.log("uuid", uuid);
      this.row.uuid = uuid;
    },
    handleChange() {
      this.$store.commit("SET_FILENAME", {
        fileName: `${this.row.userName}-${this.row.projectName}`
      });
    },
    async getTypeList() {
      this.loading = true;
      const r = await getInfoGet({}, "/api/webapi_baozhuang/get_bzlx")
        .catch(e => {
          console.log("e", e);
        })
        .finally(() => {
          this.loading = false;
        });
      if (!r) {
        this.baozhuangOptions = [];
        return;
      }
      this.baozhuangOptions = r;
    },
    sendWebsockt(data) {
      console.log("向服务器发送数据", data, this.ws);
      // 向服务器发送数据
      this.ws.send(
        JSON.stringify({
          type: "client",
          data
        })
      );
    },
    // 确定提交
    async confirmCreate(formName) {
      if (this.loading) return;
      const valid = await this.$refs[formName]
        .validate() // 验证是否添写必填字段
        .catch(err => {
          console.log(err);
          return false;
        });
      // console.log('valid——添加商品——382行', valid)
      if (!valid) return false;
      if (!this.row.uuid) {
        this.$message({
          type: "danger",
          message: `请上传申请表`
        });
        return;
      }
      this.loading = true;
      const r = await getInfoPost(
        { ...this.row },
        "/api/webapi_baozhuang/add_bz"
      )
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.dialogVisible = false;
        });
      if (!r.code || r.code !== 100) return;
      let proxyRow = Object.assign({}, this.row);
      this.sendWebsockt(proxyRow);
      this.$refs[formName].resetFields();
      this.$refs.getUploadClearFn.clearPictureFiles();
      this.row.uuid = "";
      this.$notify({
        title: "提交成功",
        dangerouslyUseHTMLString: true,
        type: "success"
      });
    }
  }
};
</script>

<style>
@media screen and (min-width: 320px) and (max-width: 970px) {
  .el-dialog {
    width: 90% !important;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (min-width: 1000px) {
  .el-message-box {
    width: 870px;
  }
  ::v-deep .el-form-item__content {
    line-height: 26px;
  }
  .baozhuang-box {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 970px) {
  .el-dialog {
    width: 90% important;
  }
  .baozhuang-box {
    padding: 0 15px;
  }
}
</style>
